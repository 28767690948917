import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { ImageCardStyles } from "../../../../../../UI/CommonStyles";
import {
    FontSize,
    FormSwitch,
    ImageCard
} from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { cloneDeep, isEqual } from "lodash";

const columnState = [
    {
        label: "",
        layout: "priceLeft",
        iconName: "itemLayoutLeft"
    },
    {
        label: "",
        layout: "priceRight",
        iconName: "itemLayoutRight"
    },
    {
        label: "",
        layout: "priceBottom",
        iconName: "itemLayoutBottom"
    },
    {
        label: "",
        layout: "priceWithDescription",
        iconName: "itemLayoutWithDesc"
    },
    {
        label: "",
        layout: "priceCenter",
        iconName: "itemLayoutCenter"
    },
    {
        label: "",
        layout: "priceWithDescriptionCenter",
        iconName: "itemLayoutWithDescCenter"
    },
    {
        label: "",
        layout: "priceTopWithDescCenter",
        iconName: "itemLayoutCenterPriceTop"
    },
    {
        label: "",
        layout: "priceWithDescSingleLineCenter",
        iconName: "itemLayoutSingleLineCenter"
    },
    {
        label: "",
        layout: "priceTopWithDescRight",
        iconName: "itemLayoutRightPriceTop"
    },
    {
        label: "",
        layout: "priceWithDescSingleLineRight",
        iconName: "itemLayoutSingleLineRight"
    }
];

export default function ItemLayout({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex]?.data?.styles?.item;

    const [fontSize, setFontSize] = useState(
        blockToUpdate?.itemLayout?.notationSize
    );
    const [layout, setLayout] = useState(blockToUpdate?.itemLayout?.layout);
    const [displayDescription, setDisplayDescription] = useState(
        blockToUpdate?.itemLayout?.displayDescription
    );
    const [displayDecimal, setDisplayDecimal] = useState(
        blockToUpdate?.itemLayout?.displayDecimal
    );
    const [displayPrice, setDisplayPrice] = useState(
        blockToUpdate?.itemLayout?.displayPrice
    );
    const [displaySymbol, setDisplaySymbol] = useState(
        blockToUpdate?.itemLayout?.displaySymbol
    );
    const [displayNotation, setDisplayNotation] = useState(
        blockToUpdate?.itemLayout?.displayNotation
    );

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setFontSize(blockToUpdate?.itemLayout?.notationSize);
        setLayout(blockToUpdate?.itemLayout?.layout);
        setDisplayDecimal(blockToUpdate?.itemLayout?.displayDecimal);
        setDisplayDescription(blockToUpdate?.itemLayout?.displayDescription);
        setDisplaySymbol(blockToUpdate?.itemLayout?.displaySymbol);
        setDisplayPrice(blockToUpdate?.itemLayout?.displayPrice);
        setDisplayNotation(blockToUpdate?.itemLayout?.displayNotation);

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pageIndex, data.columnIndex, data.blockIndex, undoRedoState]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            layout,
            notationSize: fontSize,
            displayDecimal,
            displayDescription,
            displayNotation,
            displayPrice,
            displaySymbol
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles?.item;
        const itemBlockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.items?.items;

        if (blockToUpdate) {
            blockToUpdate.itemLayout = {
                ...blockToUpdate.itemLayout,
                ...newStyles
            };
            itemBlockToUpdate?.forEach((item) => {
                if (!item.styles.enabled) {
                    item.styles.itemLayout = {
                        ...item.styles.itemLayout,
                        ...newStyles
                    };
                }
            });

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        layout,
        fontSize,
        displayDecimal,
        displayDescription,
        displayNotation,
        displayPrice,
        displaySymbol
    ]);

    const handleOnClick = (layout) => {
        setDependencyChanged(true);
        setLayout(layout);
    };

    return (
        <div className="collapseContentWrapper itemLayout">
            <ImageCardStyles className="imageCardWrap hasTwoCol mb-0">
                {columnState.map((item, index) => (
                    <ImageCard
                        key={index}
                        icon={item.iconName}
                        label={item.label}
                        onClick={() => handleOnClick(item.layout)}
                        isActive={layout === item.layout}
                    />
                ))}
            </ImageCardStyles>
            <FormSwitch
                value={displayDescription}
                setValue={setDisplayDescription}
                label="Display Item Description"
                setDependencyChanged={setDependencyChanged}
            />
            <FormSwitch
                value={displayPrice}
                setValue={setDisplayPrice}
                label="Display Item Price"
                setDependencyChanged={setDependencyChanged}
            />
            <FormSwitch
                value={displayDecimal}
                setValue={setDisplayDecimal}
                label="Display Empty Decimals (.00)"
                setDependencyChanged={setDependencyChanged}
            />
            <FormSwitch
                value={displaySymbol}
                setValue={setDisplaySymbol}
                label="Display Currency Symbol ($)"
                setDependencyChanged={setDependencyChanged}
            />
            <Box style={{ position: "relative" }}>
                <FormSwitch
                    value={displayNotation}
                    setValue={setDisplayNotation}
                    label="Display Allergies & Notations"
                    setDependencyChanged={setDependencyChanged}
                />
                <Box style={{ position: "absolute", right: 0, bottom: 5 }}>
                    <FontSize
                        fontSize={fontSize}
                        setFontSize={setFontSize}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
        </div>
    );
}
