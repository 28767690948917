import { Slide, Snackbar } from "@mui/material";
import { Root } from "./styles.js";
import Icon from "../../UI/Icon";

const NewVersionAlert = ({ showUpdatedMessage, setShowUpdatedMessage }) => {
    return (
        <Snackbar
            style={{
                zIndex: !showUpdatedMessage ? -1 : 2147483005
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={showUpdatedMessage}
            autoHideDuration={4000}
            onClose={() => setShowUpdatedMessage(false)}
            TransitionComponent={(props) => (
                <Slide {...props} direction="right" />
            )}
        >
            <Root className="newVersionBanner">
                <Icon name="refresh" className="icon" />
                <div>
                    <p className="bannerTitle">Application Updated!</p>
                    <p className="bannerDescription">
                        Your app just updated to the latest MilesCX version!
                    </p>
                </div>
            </Root>
        </Snackbar>
    );
};

export default NewVersionAlert;
