import { Button, Grid } from "@mui/material";
import { Root } from "./styles";

export default function NotFound() {
    return (
        <Root container className="container">
            <Grid
                item
                xs={12}
                className="grid"
                style={{
                    flexDirection: "column"
                }}
            >
                <img
                    src="/images/stellar-menus-for-white-bg.webp"
                    alt="MilesCX Logo"
                    className="image"
                ></img>
                <h1 className="title">404</h1>
                <h2 className="subtitle">
                    The page you are trying to access was not found. <br></br>{" "}
                    If you believe this is an error, please contact customer
                    support.
                </h2>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className="button"
                    onClick={() =>
                        (window.location.href =
                            process.env.REACT_APP_MENU_MANAGER || "#")
                    }
                >
                    GO BACK
                </Button>
            </Grid>
        </Root>
    );
}
