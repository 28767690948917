import { cloneDeep } from "lodash";
import { usePrinterContext } from "../context/printer";

const FONT_FAMILY = {
    fontFamily: "Roboto"
};

const REGULAR_WEIGHT = {
    fontWeight: 400
};

const REGULAR_FONT = {
    ...FONT_FAMILY,
    ...REGULAR_WEIGHT,
    fontSize: "14",
    lineHeight: "22",
    letterSpacing: 0
};

export const DEFAULT_BACKGROUND = {
    url: "",
    cover: false,
    transparency: 50,
    location: "",
    size: 50,
    color: "#ffffff"
};

export const DEFAULT_BORDER = {
    position: {
        top: false,
        right: false,
        bottom: false,
        left: false
    },
    radius: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    style: "solid",
    width: 1,
    color: "#000000"
};

export const DEFAULT_PADDING = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
};

export const ITEM_BLOCK_STYLES = {
    enabled: false,
    itemName: {
        ...REGULAR_FONT,
        lineHeight: "20",
        letterSpacing: 3,
        fontWeight: 500,
        fontStyle: "normal",
        boldFont: "normal",
        color: "#000000",
        upperCase: false,
        displayIndent: false
    },
    itemDescription: {
        ...FONT_FAMILY,
        fontSize: "12",
        lineHeight: "16",
        fontWeight: 300,
        fontStyle: "italic",
        boldFont: "normal",
        color: "#000000",
        letterSpacing: 0,
        upperCase: false,
        displayIndent: false
    },
    itemPrice: {
        ...FONT_FAMILY,
        fontWeight: 300,
        fontSize: "14",
        lineHeight: "20",
        fontStyle: "normal",
        boldFont: "normal",
        color: "#000000",
        letterSpacing: 0,
        upperCase: false
    },
    itemLayout: {
        layout: "priceLeft",
        displayDescription: true,
        displayPrice: true,
        displayDecimal: false,
        displaySymbol: false,
        displayNotation: false,
        notationSize: "11"
    },
    background: {
        ...cloneDeep(DEFAULT_BACKGROUND)
    },
    border: {
        ...cloneDeep(DEFAULT_BORDER)
    },
    padding: {
        ...cloneDeep(DEFAULT_PADDING)
    }
};

export default function BlockData() {
    const { restaurant, menu, sectionItems } = usePrinterContext();

    const updatedSectionItems = {
        ...sectionItems,
        items: sectionItems?.items?.map((item) => ({
            ...item,
            styles: cloneDeep(ITEM_BLOCK_STYLES)
        }))
    };

    const BLOCK_SUB_TYPE = {
        EDITABLE_TEXT: "editable-text",
        TEXT: "text",
        IMAGE: "image",
        DIVIDER: "divider",
        SECTION: "section"
    };

    const BLOCKTYPE = {
        RESTAURANT_LOGO: "restaurant-logo",
        MENU_TITLE: "menu-title",
        MENU_IMAGE: "menu-image",
        CUSTOM_PARAGRAPH: "custom-paragraph",
        CUSTOM_TITLE: "custom-title",
        MENU_HEADER: "menu-header",
        MENU_FOOTER: "menu-footer",
        DIVIDER: "divider",
        SECTION_ITEMS: "section-items",
        COLUMN_CONTAINER: "column-container",
        ITEM_STYLES: "item-styles"
    };

    const BLOCKSTYLES = {
        RESTAURANT_LOGO: {
            url: `${restaurant?.logo_image || ""}`,
            alt: `${restaurant?.display_name || ""}`,
            styles: {
                alignment: "center",
                width: 50
            }
        },
        MENU_TITLE: {
            text: `${menu?.displayName || ""}`,
            styles: {
                ...FONT_FAMILY,
                ...REGULAR_WEIGHT,
                fontSize: "24",
                lineHeight: "32",
                textAlign: "center",
                fontStyle: "normal",
                boldFont: "normal",
                color: "#000000",
                letterSpacing: 0,
                upperCase: false
            }
        },
        MENU_IMAGE: {
            url: "",
            styles: {
                alignment: "center",
                width: 50
            }
        },
        CUSTOM_PARAGRAPH: {
            text: "This is a custom paragraph block. Select the edit (pencil) icon on the section toolbar below to edit text.",
            editable: true,
            styles: {
                ...REGULAR_FONT,
                textAlign: "center",
                fontStyle: "normal",
                boldFont: "normal",
                color: "#000000",
                upperCase: false
            }
        },
        CUSTOM_TITLE: {
            text: "This is a custom title",
            editable: true,
            styles: {
                ...FONT_FAMILY,
                ...REGULAR_WEIGHT,
                fontSize: "24",
                lineHeight: "32",
                textAlign: "center",
                fontStyle: "normal",
                boldFont: "normal",
                color: "#000000",
                letterSpacing: 0,
                upperCase: false
            }
        },
        MENU_HEADER: {
            text: `${menu?.headerText || ""}`,
            styles: {
                ...REGULAR_FONT,
                textAlign: "center",
                fontStyle: "normal",
                boldFont: "normal",
                color: "#000000",
                upperCase: false
            }
        },
        MENU_FOOTER: {
            text: `${menu?.footerText || ""}`,
            styles: {
                ...FONT_FAMILY,
                ...REGULAR_WEIGHT,
                fontSize: "11",
                lineHeight: "13",
                letterSpacing: 0,
                textAlign: "center",
                fontStyle: "normal",
                boldFont: "normal",
                color: "#000000",
                upperCase: false
            }
        },
        DIVIDER: { height: 25 },
        SECTION_ITEMS: {
            items: updatedSectionItems,
            styles: {
                sectionLayout: {
                    column: 1,
                    columnSpace: 16,
                    itemSpace: 5,
                    topPadding: 0,
                    bottomPadding: 0,
                    leftPadding: 0,
                    rightPadding: 0,
                    titleItemSpace: 10
                },
                sectionTitle: {
                    divider: "side",
                    ...FONT_FAMILY,
                    fontWeight: 700,
                    fontSize: "28",
                    lineHeight: "32",
                    textAlign: "center",
                    fontStyle: "normal",
                    boldFont: "normal",
                    color: "#000000",
                    displayTitle: true,
                    letterSpacing: 0,
                    upperCase: false,
                    dividerColor: "#000000",
                    dividerThickness: 2,
                    bgColor: "#ffffff"
                },
                sectionHeader: {
                    ...REGULAR_FONT,
                    fontSize: "13",
                    lineHeight: "16",
                    textAlign: "center",
                    fontStyle: "normal",
                    boldFont: "normal",
                    color: "#000000",
                    upperCase: false,
                    headerSpace: 0
                },
                subSectionTitle: {
                    ...FONT_FAMILY,
                    fontWeight: 900,
                    fontSize: "16",
                    lineHeight: "18",
                    textAlign: "center",
                    fontStyle: "normal",
                    boldFont: "normal",
                    color: "#000000",
                    sectionSpace: 10,
                    letterSpacing: 18,
                    upperCase: true
                },
                background: {
                    url: "",
                    cover: false,
                    transparency: 50,
                    location: "",
                    size: 50,
                    color: "#ffffff"
                },
                border: {
                    style: "solid",
                    position: {
                        top: false,
                        right: false,
                        bottom: false,
                        left: false
                    },
                    radius: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    },
                    width: 1,
                    color: "#000000"
                },
                item: {
                    ...cloneDeep(ITEM_BLOCK_STYLES)
                }
            }
        }
    };

    return {
        BLOCK_SUB_TYPE,
        BLOCKSTYLES,
        BLOCKTYPE
    };
}
