import { useCallback } from "react";
import ReactToPrint from "react-to-print";
import { usePrinterContext } from "../context/printer";
import Button from "../components/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import fetchRequest from "./fetchRequest";
import { useAlertContext } from "../context/alert";

export default function HandlePrint({
    type = "",
    buttonText = "",
    setOverflowPopup
}) {
    const { menu, layoutState, printPageElement, setPrintLoading } =
        usePrinterContext();
    const { setAlertState, setAlert } = useAlertContext();

    const { menu: menuId } = useParams();

    const { pageLayout } = layoutState;
    const orientation = pageLayout.activeOrientation;

    const getPageContent = useCallback(() => {
        return printPageElement.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printPageElement.current]);

    function setPrintPaperConfigs() {
        const width =
            orientation === "portrait"
                ? pageLayout.paperSize.width
                : orientation === "landscape"
                ? pageLayout.paperSize.height
                : "";
        const height =
            orientation === "portrait"
                ? pageLayout.paperSize.height
                : orientation === "landscape"
                ? pageLayout.paperSize.width
                : "";
        const css = `@page {
            size: ${width} ${height};
            margin: 0mm; 
        }`;
        const head = document.head || document.getElementsByTagName("head")[0];
        const style = document.createElement("style");

        style.media = "print";
        style.appendChild(document.createTextNode(css));

        head.appendChild(style);
    }

    function handleBeforePrint(e) {}

    async function handleAfterPrint(e) {}

    const get_PDF_in_base64 = async (htmldoc) => {
        if (htmldoc) {
            const hideBlocks = Array.from(
                htmldoc.querySelectorAll(".hide_on_print")
            );
            const removeStyle = Array.from(
                htmldoc.querySelectorAll(".printPageElement")
            );
            const removeBorders = Array.from(
                htmldoc.querySelectorAll(".remove_border")
            );
            const removeBackground = Array.from(
                htmldoc.querySelectorAll(".remove_bg")
            );
            const removeClass = Array.from(
                htmldoc.querySelectorAll(".selectedItem")
            );
            removeClass.forEach((page) =>
                page.classList.remove("selectedItem")
            );

            removeBackground.forEach(
                (page) => (page.style.backgroundColor = "transparent")
            );
            removeStyle.forEach((page) => (page.style = ""));
            hideBlocks.forEach((page) => (page.style.display = "none"));
            removeBorders.forEach((page) => {
                page.style.border = "0";
                page.style.boxShadow = "none";
            });

            const totalPage =
                htmldoc.querySelectorAll(".PageEditorWrap").length;

            const pdf = new jsPDF({
                orientation: `${orientation}`,
                unit: `px`,
                format: `${pageLayout.paperSize.name}`,
                putOnlyUsedFonts: true,
                hotfixes: ["px_scaling"]
            });

            for (let i = 0; i < totalPage; i++) {
                const page = htmldoc.querySelectorAll(".PageEditorWrap")[i];
                page.style.margin = "0 !important";
                page.style.padding = "0 !important";
                const elemWidth = page.offsetWidth;
                const elemHeight = page.offsetHeight;
                const options = {
                    width: elemWidth,
                    height: elemHeight,
                    windowWidth: elemWidth,
                    windowHeight: elemHeight,
                    scale: 6,
                    useCORS: true,
                    allowTaint: true
                };
                const canvas = await html2canvas(page, options);

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                if (i > 0) {
                    pdf.addPage(); // Add a new page for each additional page
                }

                pdf.addImage({
                    imageData: canvas.toDataURL("image/JPEG"),
                    format: "JPEG",
                    x: 0,
                    y: 0,
                    width: pdfWidth,
                    height: pdfHeight
                });
            }
            const fileName = `${menu.displayName.replace(/ /g, "_")}-${
                menu.identifier
            }.pdf`;

            const pdfBase64 = pdf.output("datauristring", fileName);
            // pdf.output("dataurlnewwindow", fileName);
            try {
                const { success, response } = await fetchRequest(
                    `/media/${menu?.identifier}/upload-pdf/${menuId}`,
                    {
                        method: "POST",
                        body: JSON.stringify({ pdfData: pdfBase64 })
                    }
                );
                if (response && success) {
                    const updatedURL = response?.data?.assetUrl
                        .replace(/#/g, "%23")
                        .replace(/%/g, "%25")
                        .replace(/\+/g, "%2B");
                    window.open(updatedURL, "_blank");
                }
            } catch (error) {
                setAlert({
                    type: "error",
                    message: error.message || error.error
                });
                setAlertState(true);
                setPrintLoading(false);
            }
            // pdf.save(fileName);
        }
        setPrintLoading(false);
    };
    return (
        <ReactToPrint
            content={getPageContent}
            documentTitle={`${menu.displayName}-${menu.identifier}`}
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={() => {
                setPrintPaperConfigs();
                setPrintLoading(true);
                setOverflowPopup && setOverflowPopup(false);
            }}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={false}
            trigger={() => (
                <Button
                    type={type ? type : "primary"}
                    color="orange"
                    innerText={buttonText.length > 0 ? buttonText : "Print"}
                    className="print_btn"
                    icon={buttonText.length > 0 ? "" : "print"}
                ></Button>
            )}
            print={async (printIframe) => {
                const document = printIframe.contentDocument;
                if (document) {
                    const html = document.getElementsByTagName("html")[0];
                    get_PDF_in_base64(html);
                } else {
                    setPrintLoading(false);
                }
            }}
            onPrintError={(error) => console.log(error, "error")}
        />
    );
}
