import { ReactComponent as ArrowBack } from "../../assets/icons/ArrowBack.svg";
import { ReactComponent as Preview } from "../../assets/icons/Preview.svg";
import { ReactComponent as Print } from "../../assets/icons/Print.svg";
import { ReactComponent as Save } from "../../assets/icons/Save.svg";
import { ReactComponent as Layout } from "../../assets/icons/Layout.svg";
import { ReactComponent as Content } from "../../assets/icons/Content.svg";
import { ReactComponent as Style } from "../../assets/icons/Style.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/InfoIcon.svg";
import { ReactComponent as RestaurantLogo } from "../../assets/icons/RestaurantLogo.svg";
import { ReactComponent as MenuTitle } from "../../assets/icons/MenuTitle.svg";
import { ReactComponent as Divider } from "../../assets/icons/Divider.svg";
import { ReactComponent as MenuHeader } from "../../assets/icons/MenuHeader.svg";
import { ReactComponent as MenuFooter } from "../../assets/icons/MenuFooter.svg";
import { ReactComponent as CustomParagraph } from "../../assets/icons/CustomParagraph.svg";
import { ReactComponent as CustomTitle } from "../../assets/icons/CustomTitle.svg";
import { ReactComponent as MenuImage } from "../../assets/icons/MenuImage.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDown.svg";
import { ReactComponent as Portrait } from "../../assets/icons/Portrait.svg";
import { ReactComponent as Landscape } from "../../assets/icons/Landscape.svg";
import { ReactComponent as Land1Col } from "../../assets/icons/Land-1-Col.svg";
import { ReactComponent as Land2Col } from "../../assets/icons/Land-2-Col.svg";
import { ReactComponent as Land3Col } from "../../assets/icons/Land-3-Col.svg";
import { ReactComponent as Land4Col } from "../../assets/icons/Land-4-Col.svg";
import { ReactComponent as Land12Col } from "../../assets/icons/Land-1-2-Col.svg";
import { ReactComponent as Land12Col2 } from "../../assets/icons/Land-1-2-Col2.svg";
import { ReactComponent as Port1Col } from "../../assets/icons/Port-1-Col.svg";
import { ReactComponent as Port2Col } from "../../assets/icons/Port-2-Col.svg";
import { ReactComponent as Port3Col } from "../../assets/icons/Port-3-Col.svg";
import { ReactComponent as Port12Col } from "../../assets/icons/Port-1-2-Col.svg";
import { ReactComponent as GridLayout } from "../../assets/icons/GridLayout.svg";
import { ReactComponent as GridLayoutLandscape } from "../../assets/icons/GridLayoutLandscape.svg";
import { ReactComponent as SpoonKnifeIcon } from "../../assets/icons/SpoonKnifeIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/PlusIcon.svg";
import { ReactComponent as UpwardArrowIcon } from "../../assets/icons/UpwardArrowIcon.svg";
import { ReactComponent as DownwardArrowIcon } from "../../assets/icons/DownwardArrowIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/DeleteIcon.svg";
import { ReactComponent as AddFileIcon } from "../../assets/icons/AddFileIcon.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/FileIcon.svg";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/ZoomInIcon.svg";
import { ReactComponent as ZoomOutIcon } from "../../assets/icons/ZoomOutIcon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/RefreshIcon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/ErrorIcon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/CheckCircleIcon.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/ClearIcon.svg";
import { ReactComponent as InfoIcon2 } from "../../assets/icons/InfoIcon2.svg";
import { ReactComponent as PlusRounded } from "../../assets/icons/PlusRounded.svg";
import { ReactComponent as PlaceHolderImage } from "../../assets/icons/PlaceHolderImage.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/SortIcon.svg";
import { ReactComponent as SortDotIcon } from "../../assets/icons/SortDotIcon.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/PencilIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/EditIcon.svg";
import { ReactComponent as LeftAlignIcon } from "../../assets/icons/LeftAlignIcon.svg";
import { ReactComponent as CenterAlignIcon } from "../../assets/icons/CenterAlignIcon.svg";
import { ReactComponent as RightAlignIcon } from "../../assets/icons/RightAlignIcon.svg";
import { ReactComponent as ItalicIcon } from "../../assets/icons/ItalicIcon.svg";
import { ReactComponent as BoldIcon } from "../../assets/icons/BoldIcon.svg";
import { ReactComponent as LineHeightIcon } from "../../assets/icons/LineHeightIcon.svg";
import { ReactComponent as FontSizeIcon } from "../../assets/icons/FontSizeIcon.svg";
import { ReactComponent as LetterSpacing } from "../../assets/icons/LetterSpacing.svg";
import { ReactComponent as AllCapsIcon } from "../../assets/icons/AllCapsIcon.svg";
import { ReactComponent as NoImage } from "../../assets/icons/NoImage.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/RemoveIcon.svg";
import { ReactComponent as StylesIcon } from "../../assets/icons/StylesIcon.svg";
import { ReactComponent as columnOneIcon } from "../../assets/icons/ColumnOneIcon.svg";
import { ReactComponent as columnTwoIcon } from "../../assets/icons/ColumnTwoIcon.svg";
import { ReactComponent as BottomDivider } from "../../assets/icons/BottomDivider.svg";
import { ReactComponent as SideDivider } from "../../assets/icons/SideDivider.svg";
import { ReactComponent as NoDivider } from "../../assets/icons/NoDivider.svg";
import { ReactComponent as ItemLayoutLeft } from "../../assets/icons/ItemLayoutLeft.svg";
import { ReactComponent as ItemLayoutRight } from "../../assets/icons/ItemLayoutRight.svg";
import { ReactComponent as ItemLayoutCenter } from "../../assets/icons/ItemLayoutCenter.svg";
import { ReactComponent as ItemLayoutBottom } from "../../assets/icons/ItemLayoutBottom.svg";
import { ReactComponent as ItemLayoutWithDesc } from "../../assets/icons/ItemLayoutWithDesc.svg";
import { ReactComponent as ItemLayoutWithDescCenter } from "../../assets/icons/ItemLayoutWithDescCenter.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as CheckBoxActive } from "../../assets/icons/CheckBoxActive.svg";
import { ReactComponent as CheckBoxEmpty } from "../../assets/icons/CheckBoxEmpty.svg";
import { ReactComponent as RadioActive } from "../../assets/icons/radioActive.svg";
import { ReactComponent as RadioEmpty } from "../../assets/icons/radioEmpty.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/CancelIcon.svg";
import { ReactComponent as UndoIcon } from "../../assets/icons/UndoIcon.svg";
import { ReactComponent as RedoIcon } from "../../assets/icons/RedoIcon.svg";
import { ReactComponent as BottomRightBorder } from "../../assets/icons/BottomRightBorder.svg";
import { ReactComponent as BottomLeftBorder } from "../../assets/icons/BottomLeftBorder.svg";
import { ReactComponent as TopRightBorder } from "../../assets/icons/TopRightBorder.svg";
import { ReactComponent as TopLeftBorder } from "../../assets/icons/TopLeftBorder.svg";
import { ReactComponent as ItemLayoutSingleLineCenter } from "../../assets/icons/ItemLayoutSingleLineCenter.svg";
import { ReactComponent as ItemLayoutCenterPriceTop } from "../../assets/icons/ItemLayoutCenterPriceTop.svg";
import { ReactComponent as ItemLayoutSingleLineRight } from "../../assets/icons/ItemLayoutSingleLineRight.svg";
import { ReactComponent as ItemLayoutRightPriceTop } from "../../assets/icons/ItemLayoutRightPriceTop.svg";

const iconTypes = {
    itemLayoutRightPriceTop: ItemLayoutRightPriceTop,
    itemLayoutSingleLineRight: ItemLayoutSingleLineRight,
    itemLayoutCenterPriceTop: ItemLayoutCenterPriceTop,
    itemLayoutSingleLineCenter: ItemLayoutSingleLineCenter,
    topRightBorder: TopRightBorder,
    topLeftBorder: TopLeftBorder,
    bottomRightBorder: BottomRightBorder,
    bottomLeftBorder: BottomLeftBorder,
    undo: UndoIcon,
    redo: RedoIcon,
    cancelIcon: CancelIcon,
    radioActive: RadioActive,
    radioEmpty: RadioEmpty,
    checkboxActive: CheckBoxActive,
    checkboxEmpty: CheckBoxEmpty,
    warning: Warning,
    itemLayoutRight: ItemLayoutRight,
    itemLayoutCenter: ItemLayoutCenter,
    itemLayoutBottom: ItemLayoutBottom,
    itemLayoutWithDescCenter: ItemLayoutWithDescCenter,
    itemLayoutWithDesc: ItemLayoutWithDesc,
    itemLayoutLeft: ItemLayoutLeft,
    bottomDivider: BottomDivider,
    noDivider: NoDivider,
    sideDivider: SideDivider,
    columnTwoIcon: columnTwoIcon,
    columnOneIcon: columnOneIcon,
    stylesIcon: StylesIcon,
    remove: RemoveIcon,
    noImage: NoImage,
    lineHeightIcon: LineHeightIcon,
    fontSizeIcon: FontSizeIcon,
    letterSpacing: LetterSpacing,
    allCapsIcon: AllCapsIcon,
    rightAlignIcon: RightAlignIcon,
    centerAlignIcon: CenterAlignIcon,
    leftAlignIcon: LeftAlignIcon,
    italicIcon: ItalicIcon,
    boldIcon: BoldIcon,
    pencil: PencilIcon,
    editIcon: EditIcon,
    sortDotIcon: SortDotIcon,
    sort: SortIcon,
    placeHolderImage: PlaceHolderImage,
    plusRounded: PlusRounded,
    clear: ClearIcon,
    check: CheckCircleIcon,
    error: ErrorIcon,
    refresh: RefreshIcon,
    spoonKnifeIcon: SpoonKnifeIcon,
    land1Col: Land1Col,
    land2Col: Land2Col,
    land3Col: Land3Col,
    land4Col: Land4Col,
    land12Col: Land12Col,
    land12Col2: Land12Col2,
    port1Col: Port1Col,
    port2Col: Port2Col,
    port3Col: Port3Col,
    port12Col: Port12Col,
    gridLayout: GridLayout,
    gridLayoutLandscape: GridLayoutLandscape,
    back: ArrowBack,
    preview: Preview,
    print: Print,
    save: Save,
    layout: Layout,
    content: Content,
    style: Style,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    info: InfoIcon2,
    infoIcon: InfoIcon,
    restaurantLogo: RestaurantLogo,
    menuTitle: MenuTitle,
    divider: Divider,
    menuHeader: MenuHeader,
    menuFooter: MenuFooter,
    menuImage: MenuImage,
    customParagraph: CustomParagraph,
    customTitle: CustomTitle,
    arrowDown: ArrowDown,
    portrait: Portrait,
    landscape: Landscape,
    plusIcon: PlusIcon,
    upwardArrowIcon: UpwardArrowIcon,
    downwardArrowIcon: DownwardArrowIcon,
    deleteIcon: DeleteIcon,
    addFileIcon: AddFileIcon,
    fileIcon: FileIcon,
    zoomInIcon: ZoomInIcon,
    zoomOutIcon: ZoomOutIcon
};

const Icon = ({ name, ...props }) => {
    let Icon = iconTypes[name];
    return Icon ? <Icon {...props} /> : <></>;
};

export default Icon;
